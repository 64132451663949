<template>
  <BOverlay :show="buzy">
    <BCard class="widget">
      <div class="widget-header">
        <h4>Время онлайн</h4>
        <calendar-range
          icon=""
          :start-weekday="1"
          style="color: #7367f0; text-decoration: underline;"
          :ranges="createRanges()"
          @update="updateRange"
        >
          <div>{{ rangeText || 'Сегодня' }}</div>
        </calendar-range>
      </div>
      <div
        v-if="data.length"
        class="widget-list"
      >
        <div
          v-for="item in data"
          :key="item.employee.id"
          class="widget-list--item"
        >
          <div class="widget-list--item-avatar">
            <img
              v-if="item.employee.avatar"
              :src="item.employee.avatar"
            >
            <div v-else />
          </div>
          <div class="widget-list--item-name">
            <div>{{ formatName(item.employee) }}</div>
            <div class="text-muted">
              <template v-if="item.employee.role">
                {{ item.employee.role.description }}
              </template>
              <template v-else>
                Роль не назначена
              </template>
            </div>
          </div>
          <div class="widget-list--item-time text-muted">
            {{ formatTime(item.online_time) }}
          </div>
          <div class="widget-list--item-chart">
            <apexchart
              v-if="chartOptions.labels"
              :series="chartSeries"
              width="80"
              type="donut"
              :options="chartOptions"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-muted"
      >
        Нет данных
      </div>
    </BCard>
  </BOverlay>
</template>

<script>
import { ref } from '@vue/composition-api';
import { BCard, BOverlay } from 'bootstrap-vue';
import { useStore } from '@/hooks/useStore';
import CalendarRange from '@/components/fields/calendarRange/CalendarRange.vue';

export default {
  components: {
    BCard,
    BOverlay,
    CalendarRange,
  },
  setup() {
    const getYesterday = () => {
      const today = new Date();
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      return yesterday;
    };
    const formatDate = (date) => {
      const formattedDate = date.toISOString().split('T')[0];
      return formattedDate;
    };

    const dateFrom = ref(formatDate(getYesterday()));
    const dateTo = ref(formatDate(new Date()));

    const store = useStore();
    const data = ref([]);
    const buzy = ref(true);
    const getData = async () => {
      buzy.value = true;
      const response = await store.dispatch('widgets/getEmployeesTimeOnline', {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      });

      buzy.value = false;
      if (!response?.result) return;
      data.value = response?.data;
    };
    getData();

    const chartSeries = ref([]);
    const chartOptions = ref({
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    });

    const formatName = (obj) => [obj.firstname, obj.surname, obj.patronymic].join(' ');
    const formatTime = (payload) => {
      if (payload) return payload;
      return '0ч 0м';
    };

    const createRanges = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      return {
        Сегодня: [today, today],
        Вчера: [yesterday, yesterday],
        '7 дней': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        '30 дней': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        'Этот месяц': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Предыдущий месяц': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Этот год': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Предыдущий год': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
      };
    };

    const rangeText = ref('');
    const updateRange = (payload) => {
      dateFrom.value = payload.dateFrom;
      dateTo.value = payload.dateTo;
      rangeText.value = payload.name;
      getData();
    };

    return {
      data,
      buzy,
      chartSeries,
      chartOptions,
      formatName,
      formatTime,
      createRanges,
      updateRange,
      rangeText,
    };
  },
};
</script>

<style lang="sass" scoped>
.widget
  &-header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 26px
    & h4
      margin-bottom: 0
  &-list
    display: flex
    flex-direction: column
    gap: 20px
    &--item
      display: flex
      gap: 10px
      &-avatar
        border-radius: 6px
        & > *
          width: 42px
          height: 42px
          background-color: #E9ECEF
          border-radius: 6px
      &-name
        flex: 1
        & .text-muted
          font-size: 12px
      &-time
        align-self: center
        white-space: nowrap
        font-size: 12px
</style>
