<template>
  <BRow
    style="align-content: flex-start;"
  >
    <BCol
      lg="8"
      xl="8"
    >
      <RequestsStatuses />
    </BCol>
    <BCol
      lg="4"
      xl="4"
    >
      <RequestsEmployees />
      <EmployeesTimeOnline />
    </BCol>
  </BRow>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import RequestsStatuses from './widgets/RequestsStatuses.vue';
import RequestsEmployees from './widgets/RequestsEmployees.vue';
import EmployeesTimeOnline from './widgets/EmployeesTimeOnline.vue';

export default {
  setup() {
  },
  components: {
    RequestsStatuses,
    BRow,
    BCol,
    RequestsEmployees,
    EmployeesTimeOnline,
  },
};
</script>
