<template>
  <BOverlay :show="buzy">
    <BCard class="widget">
      <div class="widget-header">
        <h4>Заявки</h4>
        <calendar-range
          icon=""
          :start-weekday="1"
          style="color: #7367f0; text-decoration: underline;"
          :ranges="createRanges()"
          @update="updateRange"
        >
          <div>{{ rangeText || '7 дней' }}</div>
        </calendar-range>
      </div>
      <div class="widget-body">
        <div class="widget-body-info">
          <div class="widget-body-info-all">
            <div class="widget-body-info-all--count">
              {{ allRequestsCount }}
            </div>
            <div class="widget-body-info-all--name">
              Всего заявок
            </div>
          </div>
          <div class="widget-body-info-list">
            <div
              v-for="item in data"
              :key="item.status.id"
              class="widget-body-info-list--item"
            >
              <div
                class="widget-body-info-list--item-ball"
                :style="`color: rgb(${item.status.color})`"
              />
              <div class="widget-body-info-list--item-name">
                {{ item.status.name }}
              </div>
              <div class="widget-body-info-list--item-count">
                {{ item.requests_count }}
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body-chart">
          <apexchart
            v-if="chartOptions.labels"
            :series="chartSeries"
            width="320"
            type="pie"
            :options="chartOptions"
          />
        </div>
      </div>
    </BCard>
  </BOverlay>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import { BCard, BOverlay } from 'bootstrap-vue';
import { useStore } from '@/hooks/useStore';
import CalendarRange from '@/components/fields/calendarRange/CalendarRange.vue';

export default {
  components: {
    BCard,
    BOverlay,
    CalendarRange,
  },
  setup() {
    const getLastWeek = () => {
      const today = new Date();
      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
      return lastWeek;
    };
    const formatDate = (date) => {
      const formattedDate = date.toISOString().split('T')[0];
      return formattedDate;
    };

    const dateFrom = ref(formatDate(getLastWeek()));
    const dateTo = ref(formatDate(new Date()));

    const store = useStore();
    const data = ref([]);
    const buzy = ref(true);
    const getData = async () => {
      buzy.value = true;
      const response = await store.dispatch('widgets/getRequestsStatuses', {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      });

      buzy.value = false;
      if (!response?.result) return;
      data.value = response?.data;
    };
    getData();

    const allRequestsCount = ref(0);
    const chartSeries = ref([]);
    const chartOptions = ref({
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    });

    watch(data, (payload) => {
      allRequestsCount.value = payload.reduce((prev, curr) => prev + curr.requests_count, 0);
      chartSeries.value = payload.map((i) => i.requests_count);
      chartOptions.value.labels = payload.map((i) => i.status.name);
      chartOptions.value.colors = payload.map((i) => `rgb(${i.status.color})`);
    });

    const createRanges = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      return {
        Сегодня: [today, today],
        Вчера: [yesterday, yesterday],
        '7 дней': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        '30 дней': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        'Этот месяц': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Предыдущий месяц': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Этот год': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Предыдущий год': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
      };
    };

    const rangeText = ref('');
    const updateRange = (payload) => {
      dateFrom.value = payload.dateFrom;
      dateTo.value = payload.dateTo;
      rangeText.value = payload.name;
      getData();
    };

    return {
      data,
      allRequestsCount,
      buzy,
      chartSeries,
      chartOptions,
      createRanges,
      updateRange,
      rangeText,
    };
  },
};
</script>

<style lang="sass" scoped>
.widget
  &-header
    display: flex
    align-items: center
    justify-content: space-between
    & h4
      margin-bottom: 0
  &-body
    display: flex
    gap: 24px
    &-info
      flex: 1
      &-all
        margin-top: 38px
        margin-bottom: 38px
        &--count
          font-size: 84px
          line-height: 0.9
        &--name
          font-size: 14px
          line-height: 21px
      &-list
        &--item
          display: flex
          align-items: center
          max-width: 250px
          margin-bottom: 10px
          &-ball
            width: 16px
            height: 16px
            border-radius: 100%
            margin-right: 12px
            border: 2px solid currentColor
          &-name
            font-weight: 500
            font-size: 14px
            line-height: 1
          &-count
            flex: 1
            text-align: right
    &-chart
      margin-top: 32px
</style>
